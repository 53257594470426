import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: () => import('@/views/layout/index.vue'),
      children: [
        // 配置默认展示的组件
        { path: '', component: () => import('@/views/home/index.vue') },
        {
          path: '/study',
          component: () => import('@/views/movies/index.vue'),
          children: [
            {
              path: 'movies1',
              component: () => import('@/views/movies/movies1')
            },
            {
              path: 'movies2',
              component: () => import('@/views/movies/movies2')
            },
            {
              path: 'movies3',
              component: () => import('@/views/movies/movies3')
            }
          ]
        },
        {
          path: '/playfan',
          component: () => import('@/views/playfan/index.vue'),
          children: [
            {
              path: 'playfan1',
              component: () => import('@/views/playfan/playfan1')
            },
            {
              path: 'playfan2',
              component: () => import('@/views/playfan/playfan2')
            },
            {
              path: 'playfan3',
              component: () => import('@/views/playfan/playfan3')
            }
          ]
        },
        {
          path: '/designNav',
          component: () => import('@/views/designNav/index.vue'),
          children: [
            {
              path: 'designTool',
              component: () => import('@/views/designNav/designTool')
            },
            {
              path: 'designResources',
              component: () => import('@/views/designNav/designResources')
            }
          ]
        },
        {
          path: '/softwareResources',
          component: () => import('@/views/softwareResources'),
          children: [
            {
              path: 'findSoftware',
              component: () => import('@/views/softwareResources/findSoftware')
            },
            {
              path: 'resourceSearch',
              component: () =>
                import('@/views/softwareResources/resourceSearch')
            },
            {
              path: 'onlineDoc',
              component: () => import('@/views/softwareResources/onlineDoc')
            }
          ]
        },
        {
          path: '/programmer',
          component: () => import('@/views/programmer'),
          children: [
            {
              path: 'frontEnd',
              component: () => import('@/views/programmer/frontEnd')
            },
            {
              path: 'backEnd',
              component: () => import('@/views/programmer/backEnd')
            },
            { path: 'blog', component: () => import('@/views/programmer/blog') }
          ]
        },
        {
          path: '/editWebsite',
          component: () => import('@/views/editWebsite/index.vue')
        },
        {
          path: '/junxiao',
          component: () => import('@/views/junxiao'),
          children: [
            {
              path: 'pc',
              component: () => import('@/views/junxiao/pc')
            },
            {
              path: 'mobile',
              component: () => import('@/views/junxiao/mobile')
            },
            {
              path: 'knowledge',
              component: () => import('@/views/junxiao/knowledge')
            },
            {
              path: 'work',
              component: () => import('@/views/junxiao/work')
            }
          ]
        }
      ]
    },
    // * 匹配所有的, 一般配置在路由的最后, 统一的处理边缘情况, 进行404的展示
    { path: '*', component: () => import('@/views/page404/index.vue') }
  ]
})

export default router
